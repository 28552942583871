<template>
  <div id="mian">
    <div class="header">
      <span>基本信息</span>
      <span>结算信息</span>
      <span id="fzcolor">渠道信息</span>
      <span>产品信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">支付产品</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>产品类型</p>
          </td>
          <td>
            <p>产品通道</p>
          </td>
          <td>
            <p>退款策略</p>
          </td>
          <td>
            <p>结算周期</p>
          </td>
          <td>
            <p>结算费率(%)</p>
            <p>大额费率(%)</p>
          </td>
          <td>
            <p>单日限额(元)</p>
          </td>
          <td>
            <p>单笔最小限额(元)</p>
            <p>单笔最大限额(元)</p>
          </td>
          <td>
            <p>风控阈值(%)</p>
          </td>
          <td>
            <p>统计粒度</p>
          </td>
          <td>
            <p>风控状态</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in payProducts" :key="i">
          <td style="padding-left: 10px">
            <p>{{ v.productName }}</p>
          </td>
          <td>
            <p>{{ v.payChannelName }}</p>
          </td>
          <td>
            <p>
              {{
                v.refundStrategy == "WITH_SERVICE_CHARGE"
                  ? "退手续费"
                  : v.refundStrategy == "WITHOUT_SERVICE_CHARGE"
                  ? "不退手续费"
                  : ""
              }}
            </p>
          </td>
          <td>
            <p>{{ v.settleCycle }}</p>
          </td>
          <td>
            <p>{{ v.settleRate }}</p>
            <p>{{ v.largeRate }}</p>
          </td>
          <td>
            <p>{{ v.dayLimit | formatMoney }}</p>
          </td>
          <td>
            <p>{{ v.singleMinimumLimit | formatMoney }}</p>
            <p>{{ v.singleMaximumLimit | formatMoney }}</p>
          </td>
          <td>
            <p>{{ v.riskThreshold }}</p>
          </td>
          <td>
            <p v-if="v.riskGranularity==0">常规</p>
            <p v-if="v.riskGranularity==1">1分钟</p>
            <p v-if="v.riskGranularity==3">3分钟</p>
            <p v-if="v.riskGranularity==5">5分钟</p>
          </td>
          <td>
            <p>
              <el-switch v-model="v.riskStatus" active-color="#13ce66">
              </el-switch>
            </p>
          </td>
          <td>
            <p>
              <i class="lianjie" @click="editPayTab(v, i)">编辑</i
              ><i class="lianjie" @click="delPayTab(i)">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6 class="xzjsxx" @click="addPayTab"><span>+</span> 新增支付产品</h6>
    <div class="desc">
      <h5 class="jsxx">代付产品</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>产品类型</p>
          </td>
          <td>
            <p>产品通道</p>
          </td>
          <td>
            <p>费率(%)</p>
          </td>
          <td>
            <p>对公代付(元/笔)</p>
          </td>
          <td>
            <p>对私代付(元/笔)</p>
          </td>
          <td>
            <p>单日限额(元)</p>
          </td>
          <td>
            <p>单笔限额(元)</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in agnpayProducts" :key="i">
          <td style="padding-left: 10px">
            <p>
              {{
                v.payType == "BANK_PAY_DAILY_ORDER"
                  ? "朝夕付"
                  : v.payType == "BANK_PAY_MAT_ENDOWMENT_ORDER"
                  ? "任意付"
                  : ""
              }}
            </p>
          </td>
          <td>
            <p>{{ v.agnpayChannelName }}</p>
          </td>
          <td>
            <p>{{ v.payRate }}</p>
          </td>
          <td>
            <p>{{ v.payCostPublic | formatMoney }}</p>
          </td>
          <td>
            <p>{{ v.payCostPrivate | formatMoney }}</p>
          </td>
          <td>
            <p>{{ v.dayLimit | formatMoney }}</p>
          </td>
          <td>
            <p>{{ v.singleLimit | formatMoney }}</p>
          </td>
          <td>
            <p>
              <i class="lianjie" @click="editAgnPayTab(v, i)">编辑</i
              ><i class="lianjie" @click="delAgnPayTab(i)">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6 class="xzjsxx" @click="addAgnPayTab"><span>+</span> 新增代付产品</h6>
    <div class="desc">
      <h5 class="jsxx">分账产品</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>产品类型</p>
          </td>
          <td>
            <p>产品通道</p>
          </td>
          <td>
            <p>结算费率(%)</p>
          </td>
          <td>
            <p>结算成本(元/笔)</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in allocProducts" :key="i">
          <td style="padding-left: 10px">
            <p>
              {{
                v.settleType == "AUTO"
                  ? "自动结算"
                  : v.settleType == "MANUAL"
                  ? "手工结算"
                  : v.settleType == "MANUAL_INSTANT"
                  ? "手工即时结算"
                  : ""
              }}
            </p>
          </td>
          <td>
            <p>{{ v.allocChannelName }}</p>
          </td>
          <td>
            <p>{{ v.settleRate }}</p>
          </td>
          <td>
            <p>{{ v.settleCost | formatMoney }}</p>
          </td>
          <td>
            <p>
              <i class="lianjie" @click="editAllTab(v, i)">编辑</i
              ><i class="lianjie" @click="delAllTab(i)">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6 class="xzjsxx" @click="addAllTab"><span>+</span>新增分账产品</h6>
    <div class="btn">
      <el-button @click="back" class="back">返回</el-button>
      <el-button @click="next" class="next" type="primary">完成</el-button>
    </div>

    <!-- 支付 产品弹框 -->
    <el-dialog
      :title="editPayIndex !== null ? '修改支付产品' : '新增支付产品'"
      :visible.sync="userPayShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="payFormData.productId"
            placeholder="选择产品类型"
            @change="payDropChange"
            tabindex="1"
          >
            <el-option
              v-for="(v, i) in payDropLists"
              :key="i"
              :label="v.productName"
              :value="v.productId"
              :disabled="v.disabled"
            >
            </el-option>
          </el-select>
          <div v-if="addFlag">
            <div class="overAccount_ul_title">是否指向其他产品类型</div>

            <el-select
              v-model="payFormData.payCodeStatus"
              @change="changePayCode"
              placeholder="请选择"
              tabindex="2"
            >
              <el-option
                v-for="v in directionList"
                :key="v.value"
                :label="v.label"
                :value="v.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="overAccount_ul_title"><i>*</i>退款策略</div>
          <el-select
            v-model="payFormData.refundStrategy"
            placeholder="选择退款策略"
            tabindex="3"
          >
            <el-option label="退手续费" value="WITH_SERVICE_CHARGE"></el-option>
            <el-option
              label="不退手续费"
              value="WITHOUT_SERVICE_CHARGE"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>结算费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            min="0"
            max="100"
            v-model="payFormData.settleRate"
            placeholder="输入结算费率"
            tabindex="5"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>单日限额(元)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="payFormData.dayLimit"
            min="0"
            placeholder="输入单日限额"
            tabindex="7"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>单笔最大限额(元)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="payFormData.singleMaximumLimit"
            min="0"
            placeholder="输入单笔最大限额"
            tabindex="9"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>统计粒度</div>
            <el-select
              v-model="payFormData.riskGranularity"
              placeholder="选择统计粒度"
              tabindex="11"
            >
              <el-option
                v-for="(v, i) in fineness"
                :key="i"
                :label="v.title"
                :value="v.value"
                :disabled="v.disabled"
              >
              </el-option>
            </el-select>          
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>产品通道</div>
          <el-select
            v-model="payFormData.payChannelId"
            placeholder="选择产品通道"
            tabindex="2"
          >
            <el-option
              v-for="(v, i) in payDropListsChild"
              :key="i"
              :label="v.payChannelName"
              :value="v.payChannelId"
            >
            </el-option>
          </el-select>
          <div v-if="addFlag">
            <div class="overAccount_ul_title">
              <i v-if="payFormData.payCodeStatus == 1">*</i>其他产品类型
            </div>
            <el-select
              :disabled="payFormData.payCodeStatus == 1 ? false : true"
              v-model="payFormData.thisProductId"
              placeholder="请选择"
              tabindex="1"
            >
              <el-option
                v-for="(v, i) in otherProduct"
                :key="i"
                :label="v.label"
                :value="v.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="overAccount_ul_title"><i>*</i>结算周期</div>
          <el-select
            v-model="payFormData.settleCycle"
            placeholder="选择结算周期"
            tabindex="4"
          >
            <el-option label="T+1" value="T+1"></el-option>
            <el-option label="D+1" value="D+1"></el-option>
          </el-select>
          <div class="overAccount_ul_title">大额费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            min="0"
            max="100"
            v-model="payFormData.largeRate"
            placeholder="输入大额费率"
            tabindex="6"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>单笔最小限额(元)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="payFormData.singleMinimumLimit"
            min="0"
            placeholder="输入单笔最小限额"
            tabindex="8"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>风控阈值(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            min="0"
            max="100"
            v-model="payFormData.riskThreshold"
            placeholder="输入风控阈值"
            tabindex="10"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>最小预警订单数</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="payFormData.riskMinOrderNum"
              min="0"
              placeholder="最小预警订单数"
              tabindex="12"
            ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="userPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="sureAddPayTab" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 代付产品弹框 -->
    <el-dialog
      :title="editAgnPayIndex !== null ? '修改代付产品' : '新增代付产品'"
      :visible.sync="hasPayShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="agnPayFormData.payType"
            placeholder="选择产品类型"
            @change="agnPayDropChange"
            tabindex="1"
          >
            <el-option
                v-for="(v, i) in AgnpayDropLists"
                :key="i"
                :label="AgnpayDropOption[v.payType]"
                :value="v.payType"
                :disabled="v.disabled"
              >
              </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            min="0"
            max="100"
            v-model="agnPayFormData.payRate"
            placeholder="输入费率"
            tabindex="3"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>对私代付(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="agnPayFormData.payCostPrivate"
            min="0"
            placeholder="输入价格"
            tabindex="5"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>单笔限额(元)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="agnPayFormData.singleLimit"
            min="0"
            placeholder="输入限额"
            tabindex="7"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>产品通道</div>
          <el-select
            v-model="agnPayFormData.agnpayChannelId"
            placeholder="选择产品通道"
            tabindex="2"
          >
            <el-option
              v-for="(v, i) in AgnpayDropListsChild"
              :key="i"
              :label="v.agnpayChannelName"
              :value="v.agnpayChannelId"
            >
            </el-option>
          </el-select>

          <div class="overAccount_ul_title"><i>*</i>对公代付(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="agnPayFormData.payCostPublic"
            min="0"
            placeholder="输入价格"
            tabindex="4"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>单日限额(元)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="agnPayFormData.dayLimit"
            min="0"
            placeholder="输入限额"
            tabindex="6"
          ></el-input>
          <!--              <div class="overAccount_ul_title">IP白名单</div>
              <el-input v-model="input" placeholder="输入IP"></el-input> -->
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="hasPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="sureAddAgnPayTab" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分账产品弹框 -->
    <el-dialog
      :title="editAllIndex == null ? '新增分账产品' : '修改分账产品'"
      :visible.sync="accountShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="allFormData.settleType"
            placeholder="选择产品类型"
            @change="allDropChange"
            tabindex="1"
          >
            <el-option
                v-for="(v, i) in allDropLists"
                :key="i"
                :label="allDropOption[v.settleType]"
                :value="v.settleType"
                :disabled="v.disabled"
              >
              </el-option>            
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            min="0"
            max="100"
            v-model="allFormData.settleRate"
            placeholder="输入费率"
            tabindex="3"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>产品通道</div>
          <el-select
            v-model="allFormData.allocChannelId"
            placeholder="选择产品通道"
            tabindex="2"
          >
            <el-option
              v-for="(v, i) in allDropListsChild"
              :key="i"
              :label="v.allocChannelName"
              :value="v.allocChannelId"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>结算成本(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="allFormData.settleCost"
            min="0"
            placeholder="输入结算成本"
            tabindex="4"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="accountShow = false">取 消</el-button>
        <el-button class="addBt" @click="sureAddAllTab" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { payDrop, agnpayDrop, allDrop } from "@/api/user/shopManager.js";
import { mapState, mapMutations } from "vuex";
import { isTwoDecimal, isNumber } from "@/utils/common.js";

export default {
  data() {
    return {
      userPayShow: false, //新增支付展示
      hasPayShow: false, //新增代付展示
      accountShow: false, //新增分账展示
      payProducts: [], //支付产品列表
      agnpayProducts: [], //代付产品列表
      allocProducts: [], //分账产品列表
      formData: {}, //渠道信息
      payFormData: {
        //支付产品
        productId: "", //true	支付产品ID
        payChannelId: "", //true	支付产品通道ID
        refundStrategy: null, //true	退款策略
        settleCycle: "", //true	结算周期，
        settleRate: "", //true	结算费率%
        largeRate: "", //false	大额费率%
        dayLimit: "", //true	单日限额，单位分
        singleMinimumLimit: "", //true	单笔最小限额，单位分
        singleMaximumLimit: "", //true	单笔最大限额，单位分
        riskThreshold: "", //true	风控阈值（0-100）
        riskStatus: "", //true	风控状态
        payCodeStatus: 0, //是否指向其他产品类型 1为是 0为否 默认为否
        thisProductId: "", //其他产品类型ID
        thisProductValue: "", //其他产品类型名称
        riskGranularity:"", //统计粒度
        riskMinOrderNum:"", //最小预警订单数
      },
      payDropLists: [], //支付产品类型数据可选列表
      addFlag: false, //控制支付产品弹框新增的 是否指向其他产品类型和其他产品类型 两个选项的显示隐藏

      // 是否指向其他产品类型可选项
      directionList: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],

      otherProduct: [], // 其他产品类型可选项
      payDropListsChild: [], //付产品通道列表
      editPayIndex: null,
      agnPayFormData: {
        //代付产品
        payType: "", //true	代付产品类型
        agnpayChannelId: "", //true	代付产品通道ID
        payRate: "", //true	代付费率%
        payCostPublic: "", //true	对公代付（单位分/笔）
        payCostPrivate: "", //true	对私代付（单位分/笔）
        dayLimit: "", //true	单日限额，单位分
        singleLimit: "", //true	单笔限额，单位分
      },
      AgnpayDropLists: [], //代付产品类型列表
      AgnpayDropOption:{
        BANK_PAY_DAILY_ORDER:"朝夕付",
        BANK_PAY_MAT_ENDOWMENT_ORDER:"任意付",
      },
      AgnpayDropListsChild: [], //代付产品通道列表
      editAgnPayIndex: null,
      allFormData: {
        //分账展示
        settleType: "", //true	结算方式
        allocChannelId: "", //true	分账产品通道ID
        settleRate: "", //true	结算费率%
        settleCost: "", //true	结算成本（单位分/笔）
      },
      allDropLists: [], //结算方式列表
      allDropOption:{
        AUTO:"自动结算",
        MANUAL:"手工结算",
        MANUAL_INSTANT:"手工即时结算",
      },
      allDropListsChild: [], //分账产品通道列表
      editAllIndex: null,

      buttonFlag:false,

      fineness: [
        {
          title: "常规",
          value: "0",
          disabled: false,
        },
        {
          title: "1分钟",
          value: "1",
          disabled: false,
        },
        {
          title: "3分钟",
          value: "3",
          disabled: false,
        },
        {
          title: "5分钟",
          value: "5",
          disabled: false,
        },
      ],

      routeQueryId:"",

    };
  },
  computed: {
    ...mapState({
      businessDetail: "user_businessDetail",
    }),
  },
  created() {
    this.routeQueryId = this.$route.query.id
  },
  mounted() {
    if (this.$route.query.id >= 0 && this.businessDetail) {
      let data = this.businessDetail.mchChannels
        ? this.businessDetail.mchChannels
        : [];
      let index = this.$route.query.id;
      this.formData = data[index].mchChannelDetail;
      this.payProducts = data[index].payProducts;
      this.agnpayProducts = data[index].agnpayProducts;
      this.allocProducts = data[index].allocProducts;
      this.getPayDrop();
      this.getAgnpayDrop();
      this.getAllDrop();
    }
  },
  methods: {
    ...mapMutations({
      setBusiness: "user_setBusiness",
    }),
    // 获取支付下拉选择
    getPayDrop() {
      let data = {
        orgId: this.formData.orgId,
        mchChannelId: "",
      };
      payDrop(data).then((res) => {
        this.payDropLists = res.resultData;
        this.otherProduct = [];

        this.payDropLists.forEach((v) => {
          v.disabled = false;
          if (v.productId == 13 || v.productId == 4) {
            this.otherProduct.push({
              value: v.productId,
              label: v.productName,
            });
          }
        });
      });
    },
    // 支付产品类型下拉选择时
    payDropChange(value) {
      this.payFormData.payChannelId = "";
      this.payFormData.payChannelName = "";

      this.payDropListsChild = [];
      this.payDropLists.forEach((v) => {
        if (v.productId === value) {
          this.payDropListsChild = v.payChannels;
          this.payFormData.productName = v.productName;
        }
      });
      // 新增需求 当商户开通的产品类型为微信扫码+、微信H5支付、微信App支付、公众号支付、小程序支付时，可以配置实际请求机构的产品类型.
      if (
        value == 32 ||
        value == 3 ||
        value == 14 ||
        value == 4 ||
        value == 13
      ) {
        this.payFormData.payCodeStatus = 0;
        this.payFormData.thisProductId = "";
        this.payFormData.thisProductValue = "";
        this.addFlag = true;
      } else {
        this.addFlag = false;
      }
    },
    changePayCode(val) {
      if (val != 1) {
        this.payFormData.thisProductId = "";
      }
    },
    // 新增支付
    addPayTab() {
      (this.payFormData = {
        productId: "",
        payChannelId: "",
        refundStrategy: "",
        settleCycle: "",
        settleRate: "",
        largeRate: "",
        dayLimit: "",
        singleMinimumLimit: "",
        singleMaximumLimit: "",
        riskThreshold: "",
        riskStatus: "",
        payCodeStatus: 0,
        thisProductId: "",
        thisProductValue: "",
        riskGranularity:"", //统计粒度
        riskMinOrderNum:"", //最小预警订单数 
      }),
        (this.userPayShow = true);
      this.editPayIndex = null;
      this.payDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.payProducts.forEach((v) => {
        this.payDropLists.forEach((val) => {
          if (v.productId == val.productId) {
            val.disabled = true;
          }
        });
      });
    },
    // 新增支付确认
    sureAddPayTab() {
      let data = JSON.parse(JSON.stringify(this.payFormData));

      console.log(data)

      if (!data.productId) {
        this.$message.error("产品类型必选");
        return false;
      }
      if(!data.payChannelId){
        this.$message.error("产品通道必选");
        return false;
      }
      if (!data.refundStrategy) {
        this.$message.error("退款策略必选");
        return false;
      }
      if (!data.settleCycle) {
        this.$message.error("结算周期必选");
        return false;
      }
      if (!data.settleRate && data.settleRate !== 0) {
        this.$message.error("结算费率必填");
        return false;
      } else {
        if (!isTwoDecimal(data.settleRate)) {
          this.$message.error("结算费率不合法(最多保留小数点后两位)");
          return false;
        }
      }
      if (data.settleRate < 0 || data.settleRate > 100) {
        this.$message.error("结算费率范围（0-100）");
        return false;
      }
      if (!data.largeRate && data.largeRate !== 0) {
        data.largeRate = data.settleRate;
      } else {
        if (data.largeRate < 0 || data.largeRate > 100) {
          this.$message.error("大额费率范围（0-100）");
          return false;
        }
        if (!isTwoDecimal(data.largeRate)) {
          this.$message.error("大额费率不合法(最多保留小数点后两位)");
          return false;
        }
      }
      if (!data.dayLimit && data.dayLimit !== 0) {
        this.$message.error("单日限额必填");
        return false;
      }
      if (data.dayLimit < 0) {
        this.$message.error("单日限额最小为0元");
        return;
      }
      if (!data.singleMinimumLimit && data.singleMinimumLimit !== 0) {
        this.$message.error("单笔最小限额必填");
        return false;
      }
      if (data.singleMinimumLimit < 0) {
        this.$message.error("单笔最小限额最小为0元");
        return;
      }
      if (!data.singleMaximumLimit && data.singleMaximumLimit !== 0) {
        this.$message.error("单笔最大限额必填");
        return false;
      }
      if (data.singleMaximumLimit < 0) {
        this.$message.error("单笔最大限额最小为0元");
        return;
      }
      if (!data.riskThreshold && data.riskThreshold !== 0) {
        this.$message.error("风控阈值必填");
        return false;
      } else {
        if (!isNumber(data.riskThreshold)) {
          this.$message.error("风控阈值不合法(请输入0-100正整数)");
          return false;
        }
      }
      if (data.riskThreshold < 0 || data.riskThreshold > 100) {
        this.$message.error("风控阈值范围（0-100）");
        return false;
      }
      if (data.payCodeStatus == 1 && !data.thisProductId) {
        this.$message.error("其他产品类型必选");
        return false;
      }

      if(data.dayLimit.length > 8 || data.singleMinimumLimit.length > 8 || data.singleMaximumLimit.length > 8){        
        this.$message.error("金额超过最大长度");
        return false;
      }
      if(!(/^(\d?)+(\.\d{0,2})?$/.test(data.dayLimit)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.singleMinimumLimit)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.singleMaximumLimit))){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }
      if (!data.riskGranularity) {
        this.$message.error("统计粒度必选");
        return false;
      }
      if (!data.riskMinOrderNum && data.riskMinOrderNum !== 0) {
        this.$message.error("最小预警订单数必填");
        return false;
      }
      if (data.riskMinOrderNum < 0 || !/^[0-9]*$/.test(data.riskMinOrderNum)) {
        this.$message.error("最小预警订单数至少为0,且为整数");
        return;
      }

      this.buttonFlag = true;  

      if(this.payFormData.riskGranularity == "1分钟" || this.payFormData.riskGranularity == 1){
        data.riskGranularity = 1
      }else if(this.payFormData.riskGranularity == "3分钟" || this.payFormData.riskGranularity == 3){
        data.riskGranularity = 3
      }else if(this.payFormData.riskGranularity == "5分钟" || this.payFormData.riskGranularity == 5){
        data.riskGranularity = 5
      }else{
        data.riskGranularity = 0
      }

      if (data.riskStatus == "") {
        data.riskStatus = false;
      }
      data.payChannelName = "";
      data.mchNo = this.formData.mchNo;
      data.riskThreshold = Number(data.riskThreshold);
      this.payDropLists.forEach((v) => {
        if (v.productId == data.productId) {
          data.productName = v.productName;
        }
        v.payChannels.forEach((v) => {
          if (v.payChannelId == data.payChannelId) {
            data.payChannelName = v.payChannelName;
          }
        });
      });
      if (this.editPayIndex !== null) {
        this.payProducts[this.editPayIndex] = data;
        this.payProducts = JSON.parse(JSON.stringify(this.payProducts));
      } else {
        this.payProducts.push(data);
      }
      console.log(this.payProducts,"666666666")
      data.payCodeStatus == 1
        ? (data.payCodeStatus = true)
        : (data.payCodeStatus = false);
      if (!data.payCodeStatus) {
        (data.thisProductId = ""), (data.thisProductValue = "");
      }
      // 拿到 其他产品类型名称
      if (data.thisProductId) {
        this.otherProduct.forEach((e) => {
          if (e.value == data.thisProductId) {
            data.thisProductValue = e.label;
          }
        });
      } else {
        (data.thisProductId = ""), (data.thisProductValue = "");
      }
      this.$message.success("保存成功");
      this.userPayShow = false;
      setTimeout(()=>{
        this.buttonFlag=false
      },2000)
    },
    // 修改支付
    editPayTab(value, index) {
      // this.payFormData = JSON.parse(JSON.stringify(value));
      this.payFormData.dayLimit = value.dayLimit; //true	单日限额，单位分
      this.payFormData.productId = value.productId; //true	支付产品类型ID
      this.payFormData.payChannelId = value.payChannelId; //true	支付产品通道ID
      this.payFormData.refundStrategy = value.refundStrategy; //true	退款策略
      this.payFormData.settleCycle = value.settleCycle; //true	结算周期，
      this.payFormData.settleRate = value.settleRate; //true	结算费率%
      this.payFormData.largeRate = value.largeRate; //false	大额费率%
      this.payFormData.singleMinimumLimit = value.singleMinimumLimit; //true	单笔最小限额，单位分
      this.payFormData.singleMaximumLimit = value.singleMaximumLimit; //true	单笔最大限额，单位分
      this.payFormData.riskThreshold = value.riskThreshold; //true	风控阈值（0-100）
      this.payFormData.riskStatus = value.riskStatus; //true	风控状态

      this.payFormData.riskGranularity = value.riskGranularity;
      this.payFormData.riskMinOrderNum = value.riskMinOrderNum;

      if(value.riskGranularity == 1){
        this.payFormData.riskGranularity = "1分钟"
      }else if(value.riskGranularity == 3){
        this.payFormData.riskGranularity = "3分钟"
      }else if(value.riskGranularity == 5){
        this.payFormData.riskGranularity = "5分钟"
      }else{
        this.payFormData.riskGranularity = "常规"
      }

      // 新增的需求数据
      this.payFormData.payCodeStatus = value.payCodeStatus ? 1 : 0; //是否指向其他产品类型 1为是 0为否 默认为否
      this.payFormData.thisProductId =
        this.payFormData.payCodeStatus == 1 ? value.thisProductId : ""; //其他产品类型ID
      this.payFormData.thisProductValue =
        this.payFormData.payCodeStatus == 1 ? value.thisProductValue : ""; //其他产品类型名称

      console.log("支付产品类型", value);
      this.userPayShow = true;
      this.editPayIndex = index;
      this.payDropListsChild = [];
      this.payDropLists.forEach((v) => {
        if (v.productId === this.payFormData.productId) {
          this.payDropListsChild = v.payChannels;
        }
      });
      this.payDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.payProducts.forEach((v) => {
        this.payDropLists.forEach((val) => {
          if (v.productId == val.productId) {
            val.disabled = true;
          }
        });
      });

      // 新增需求 当商户开通的产品类型为微信扫码+、微信H5支付、微信App支付、公众号支付、小程序支付时，可以配置实际请求机构的产品类型.
      if (
        value.productId == 32 ||
        value.productId == 3 ||
        value.productId == 14 ||
        value.productId == 4 ||
        value.productId == 13
      ) {
        this.addFlag = true;
      } else {
        this.payFormData.payCodeStatus = 0;
        this.payFormData.thisProductId = "";
        this.payFormData.thisProductValue = "";
        this.addFlag = false;
      }
    },
    // 删除支付
    delPayTab(index) {
      this.$confirm("此操作将删除该支付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.payProducts.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取代付下拉选择
    getAgnpayDrop() {
      let data = {
        orgId: this.formData.orgId,
        mchChannelId: "",
      };
      agnpayDrop(data).then((res) => {
        this.AgnpayDropLists = res.resultData;
      });
    },
    // 代付下拉选择
    agnPayDropChange(value) {
      this.agnPayFormData.agnpayChannelId = "";
      this.AgnpayDropListsChild = [];
      this.AgnpayDropLists.forEach((v) => {
        if (v.payType === value) {
          this.AgnpayDropListsChild = v.agnpayChannels;
        }
      });
    },
    // 新增代付
    addAgnPayTab() {
      (this.agnPayFormData = {
        payType: "",
        agnpayChannelId: "",
        payRate: "",
        payCostPublic: "",
        payCostPrivate: "",
        dayLimit: "",
        singleLimit: "",
      }),
        (this.hasPayShow = true);
      this.editAgnPayIndex = null;
      this.AgnpayDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.agnpayProducts.forEach((v) => {
        this.AgnpayDropLists.forEach((val) => {
          if (v.payType == val.payType) {
            val.disabled = true;
          }
        });
      });
    },
    // 新增代付确认
    sureAddAgnPayTab() {
      let data = JSON.parse(JSON.stringify(this.agnPayFormData));
      if (!data.payType) {
        this.$message.error("产品类型必选");
        return false;
      }
      if(!data.agnpayChannelId){
        this.$message.error("产品通道必选");
        return false;
      }
      if (!data.payRate && data.payRate !== 0) {
        this.$message.error("代付费率必填");
        return false;
      } else {
        if (!isTwoDecimal(data.payRate)) {
          this.$message.error("代付费率不合法(最多保留小数点后两位)");
          return false;
        }
      }
      if (data.payRate < 0 || data.payRate > 100) {
        this.$message.error("代付费率范围（0-100）");
        return false;
      }
      if (!data.payCostPublic && data.payCostPublic !== 0) {
        this.$message.error("对公代付必填");
        return false;
      }
      if (data.payCostPublic < 0) {
        this.$message.error("对公代付金额最小为0元");
        return;
      }
      if (!data.payCostPrivate && data.payCostPrivate !== 0) {
        this.$message.error("对私代付必填");
        return false;
      }
      if (data.payCostPrivate < 0) {
        this.$message.error("对私代付金额最小为0元");
        return;
      }
      if (!data.dayLimit && data.dayLimit !== 0) {
        this.$message.error("单日限额必填");
        return false;
      }
      if (data.dayLimit < 0) {
        this.$message.error("单日限额最小为0元");
        return;
      }
      if (!data.singleLimit && data.singleLimit !== 0) {
        this.$message.error("单笔限额必填");
        return false;
      }
      if (data.singleLimit < 0) {
        this.$message.error("单笔限额最小为0元");
        return;
      }

      if(data.payCostPublic.length > 8 || data.payCostPrivate.length > 8 || data.dayLimit.length > 8 || data.singleLimit.length > 8){        
        this.$message.error("金额超过最大长度");
        return false;
      }
      if(!(/^(\d?)+(\.\d{0,2})?$/.test(data.payCostPublic)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.payCostPrivate)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.dayLimit)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.singleLimit))){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }
      this.buttonFlag = true;

      data.agnpayChannelName = "";
      data.mchNo = this.formData.mchNo;
      this.AgnpayDropListsChild.forEach((v) => {
        if (v.agnpayChannelId == data.agnpayChannelId) {
          data.agnpayChannelName = v.agnpayChannelName;
        }
      });
      if (this.editAgnPayIndex !== null) {
        this.agnpayProducts[this.editAgnPayIndex] = data;
      } else {
        this.agnpayProducts.push(data);
      }
      this.$message.success("保存成功");
      this.hasPayShow = false;
      setTimeout(()=>{
        this.buttonFlag=false
      },2000)
    },
    // 修改代付
    editAgnPayTab(value, index) {
      this.agnPayFormData = JSON.parse(JSON.stringify(value));
      this.hasPayShow = true;
      this.editAgnPayIndex = index;
      this.AgnpayDropListsChild = [];
      this.AgnpayDropLists.forEach((v) => {
        if (v.payType === this.agnPayFormData.payType) {
          this.AgnpayDropListsChild = v.agnpayChannels;
        }
      });
      this.AgnpayDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.agnpayProducts.forEach((v) => {
        this.AgnpayDropLists.forEach((val) => {
          if (v.payType == val.payType) {
            val.disabled = true;
          }
        });
      });
    },
    // 删除代付
    delAgnPayTab(index) {
      this.$confirm("此操作将删除该代付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.agnpayProducts.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取分账下拉选择
    getAllDrop() {
      let data = {
        orgId: this.formData.orgId,
        mchChannelId: "",
      };
      allDrop(data).then((res) => {
        this.allDropLists = res.resultData;
      });
    },
    // 分账下拉选择
    allDropChange(value) {
      this.allFormData.allocChannelId = "";
      this.allDropListsChild = [];
      this.allDropLists.forEach((v) => {
        if (v.settleType === value) {
          this.allDropListsChild = v.allocChannels;
        }
      });
    },
    // 新增分账
    addAllTab() {
      (this.allFormData = {
        settleType: "",
        allocChannelId: "",
        settleRate: "",
        settleCost: "",
      }),
        (this.editAllIndex = null);
      this.accountShow = true;

      this.allDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.allocProducts.forEach((v) => {
        this.allDropLists.forEach((val) => {
          if (v.settleType == val.settleType) {
            val.disabled = true;
          }
        });
      });
    },
    // 新增分账确认
    sureAddAllTab() {
      let data = JSON.parse(JSON.stringify(this.allFormData));
      if (!data.settleType) {
        this.$message.error("产品类型必选");
        return false;
      }
      if(!data.allocChannelId){
        this.$message.error("产品通道必选");
        return false;
      }
      if (!data.settleRate && data.settleRate !== 0) {
        this.$message.error("结算费率必填");
        return false;
      } else {
        if (!isTwoDecimal(data.settleRate)) {
          this.$message.error("结算费率不合法(最多保留小数点后两位)");
          return false;
        }
      }
      if (data.settleRate < 0 || data.settleRate > 100) {
        this.$message.error("结算费率范围（0-100）");
        return false;
      }
      if (!data.settleCost && data.settleCost !== 0) {
        this.$message.error("结算成本必填");
        return false;
      }
      if(data.settleCost.length > 8){        
        this.$message.error("金额超过最大长度");
        return false;
      }
      if(!(/^(\d?)+(\.\d{0,2})?$/.test(data.settleCost))){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }
      // if (data.settleCost < 0.01) {
      //   this.$message.error('结算成本金额最小为0.01元')
      //   return;
      // }
      this.buttonFlag = true;
      data.allocChannelName = "";
      data.mchNo = this.formData.mchNo;
      this.allDropListsChild.forEach((v) => {
        if (v.allocChannelId == data.allocChannelId) {
          data.allocChannelName = v.allocChannelName;
        }
      });
      if (this.editAllIndex !== null) {
        this.allocProducts[this.editAllIndex] = data;
      } else {
        this.allocProducts.push(data);
      }
      this.$message.success("保存成功");
      this.accountShow = false;
      setTimeout(()=>{
        this.buttonFlag=false
      },2000)
    },
    // 修改分账
    editAllTab(value, index) {
      this.allFormData = JSON.parse(JSON.stringify(value));
      this.accountShow = true;
      this.editAllIndex = index;
      this.allDropListsChild = [];
      this.allDropLists.forEach((v) => {
        if (v.settleType === this.allFormData.settleType) {
          this.allDropListsChild = v.allocChannels;
        }
      });
      this.allDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.allocProducts.forEach((v) => {
        this.allDropLists.forEach((val) => {
          if (v.settleType == val.settleType) {
            val.disabled = true;
          }
        });
      });
    },
    // 删除分账
    delAllTab(index) {
      this.$confirm("此操作将删除该分账产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.allocProducts.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 返回
    back() {
      this.$router.push({
        name: "addcommercial_qdxx",
      });
    },
    // 完成
    next() {
      this.$router.push({
        name: "addcommercial_qdxx",
      });
    },
  },
  destroyed() {
    if (this.routeQueryId >= 0 && this.businessDetail) {
      let data = this.businessDetail ? this.businessDetail.mchChannels : [];
      let index = this.routeQueryId;
      data[index].payProducts = this.payProducts;
      data[index].agnpayProducts = this.agnpayProducts;
      data[index].allocProducts = this.allocProducts;
      let tabData = {
        mchDetail: this.businessDetail ? this.businessDetail.mchDetail : null,
        settlements: this.businessDetail ? this.businessDetail.settlements : null,
        mchChannels: data,
      };
      
      this.setBusiness(tabData);
    }
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}
.back:hover {
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  color: #fff;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}


.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

</style>
